import React, { useContext, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import AuthContext from '../apps/auth/AuthContext';

function Navbar() {
  const authContext = useContext(AuthContext);
  const currentUser = authContext.currentUser;
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  if (!currentUser) {
    return (
      <div className="navbar bg-black">
        <div className="navbar-start"></div>
        <div className="navbar-center"></div>
        <div className="navbar-end">
          <NavLink to="/signin" className="btn bg-black text-white">Sign In</NavLink>
        </div>
      </div>
    );
  }

  return (
    <div className="navbar bg-black">
      <div className="navbar-start">
        <div className="dropdown">
          <label tabIndex={0} className="btn btn-ghost lg:hidden" onClick={() => setIsOpen(!isOpen)}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
          </label>
          {isOpen && (
            <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-gray-200 text-black rounded-box w-52">
              <li><NavLink to="/schedule" onClick={() => setIsOpen(false)}>Schedule</NavLink></li>
              <li><NavLink to="/recipes" onClick={() => setIsOpen(false)}>Library</NavLink></li>
            </ul>
          )}
        </div>
        <NavLink to="/recipes" className="btn btn-ghost text-xl">Mise En</NavLink>
      </div>
      <div className="navbar-center hidden lg:flex">
        <div role="tablist" className="tabs tabs-boxed">
          <NavLink 
            role="tab" 
            className={location.pathname === '/schedule' ? 'tab bg-green' : 'tab'} 
            to="/schedule"
          >
            Schedule
          </NavLink>
          <NavLink 
            role="tab" 
            className={location.pathname === '/recipes' ? 'tab bg-green' : 'tab'} 
            to="/recipes"
          >
            Library
          </NavLink>
        </div>
      </div>
      <div className="navbar-end">
        {currentUser ? (
          <NavLink to="/profile" className="btn bg-black text-white">Profile</NavLink>
        ) : (
          <NavLink to="/signin" className="btn bg-black text-white">Sign In</NavLink>
        )}
      </div>
    </div>
  );
}

export default Navbar;