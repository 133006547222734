import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BuildSchedule from './BuildSchedule';
import { makeBackendRequest } from '../../api';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

function BuildMealPlanStructure() {
  const [household, setHousehold] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the household data when the component mounts
    makeBackendRequest('/get_household', 'get')
      .then(response => {
        setHousehold(response.data.members);
        // Initialize the schedule based on the household data
        const initialSchedule = createInitialSchedule(response.data.members);
        setSchedule(initialSchedule);
      })
      .catch(error => console.error(error));
  }, []);

  const createInitialSchedule = (household) => {
    const mealTypes = ['breakfast', 'lunch', 'snack', 'dinner'];
    let schedule = {};
  
    for (let i = 0; i < 14; i++) {
      const date = moment().add(i, 'days');
      const formattedDate = date.format('YYYY-MM-DD');
  
      mealTypes.forEach(mealType => {
        const mealId = uuidv4();
        schedule[mealId] = {
          id: mealId,
          date: date.toDate(),
          formattedDate: formattedDate,
          meal_type: mealType,
          users: household.reduce((acc, member) => {
            acc[member.user_id] = false;
            return acc;
          }, {})
        };
      });
    }
    return schedule;
  };

  const updateSchedule = (mealId, userId, isChecked) => {
    setSchedule(prevSchedule => ({
      ...prevSchedule,
      [mealId]: {
        ...prevSchedule[mealId],
        users: {
          ...prevSchedule[mealId].users,
          [userId]: isChecked
        }
      }
    }));
  };

  const submitWizard = () => {
    makeBackendRequest('/create_meal_plan', 'post', { meal_inspiration: 'No inspiration provided' })
      .then(response => {
        const mealPlanId = response.data.meal_plan_id;
  
        const mealsToCreate = Object.values(schedule).filter(meal => 
          Object.values(meal.users).some(user => user)
        );
  
        const mealPromises = mealsToCreate.map(meal => {
          const { formattedDate, meal_type } = meal;
          return makeBackendRequest('/create_meal', 'post', { 
            date: formattedDate,
            meal_type, 
            meal_plan_id: mealPlanId, 
            published: false 
          });
        });
  
        return Promise.all(mealPromises).then(() => mealPlanId);
      })
      .then(mealPlanId => {
        navigate(`/meal_plan/${mealPlanId}`);
      })
      .catch(error => console.error(error));
  };

  return (
    <div className="max-w-[600px] justify-center mx-auto px-4">
        <div className="flex flex-wrap justify-between items-end pt-16 pb-4 relative">
            <BuildSchedule schedule={schedule} household={household} updateSchedule={updateSchedule} />
        </div>
        <div className="fixed bottom-0 left-0 right-0 bg-gray-800 text-white p-4 flex justify-between">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={submitWizard}>Create Meal Plan</button>
        </div>
    </div>
  );
}

export default BuildMealPlanStructure;