import React from 'react';
import StickyNote from '../../components/StickyNote';
import { Button } from '../../components/Button';

const PreferenceStickyNote = ({ preferenceKey, value, onEdit, onDelete }) => {
  return (
    <StickyNote className="w-full">
      <div className="flex flex-col space-y-2">
        <p className="text-sm">{typeof value === 'object' ? JSON.stringify(value) : value}</p>
        <div className="flex justify-start space-x-2">
          <Button size="sm" variant="noShadow" className="bg-gray-300 hover:bg-gray-200" onClick={() => onEdit(preferenceKey, value)}>Edit</Button>
          <Button size="sm" variant="noShadow" className="bg-gray-300 hover:bg-gray-200" onClick={() => onDelete(preferenceKey)}>Delete</Button>
        </div>
      </div>
    </StickyNote>
  );
};

export default PreferenceStickyNote;