import React, { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from 'src/components/Dialog';
import { Button } from 'src/components/Button';
import {TextArea } from 'src/components/TextArea';

const FeedbackModal = ({ isOpen, onClose, onSubmitFeedback }) => {
    const [feedback, setFeedback] = useState('');

    const handleSubmit = () => {
        onSubmitFeedback(feedback);
        onClose();
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="bg-black text-white">
                <DialogHeader>
                    <DialogTitle>Feedback on meal ideas</DialogTitle>
                </DialogHeader>
                <div className="p-4">
                    <TextArea
                        placeholder="How should we change what we propose?"
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                    />
                </div>
                <DialogFooter>
                    <Button onClick={handleSubmit}>Submit</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default FeedbackModal;