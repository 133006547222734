// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDIzC-Gg6fyyLjdzLO9Hh7MsGKqqmkcLBM",
    authDomain: "nutritionio.firebaseapp.com",
    projectId: "nutritionio",
    storageBucket: "nutritionio.appspot.com",
    messagingSenderId: "37627154752",
    appId: "1:37627154752:web:d49944222b7d9094fa2aa5",
    measurementId: "G-HHJX71R5SW"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth();

export { app, auth };