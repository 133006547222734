import React from 'react';
import CreateRecipe from './CreateRecipe';
import { useAddRecipeToMealMutation } from 'src/store/apiSlice';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from 'src/components/Dialog';

interface CreateRecipeModalProps {
  mealId: string;
  initialMessage: string;
  isOpen: boolean;
  onClose: () => void;
}

const CreateRecipeModal: React.FC<CreateRecipeModalProps> = ({ mealId, initialMessage, isOpen, onClose }) => {
  const [addRecipeToMeal] = useAddRecipeToMealMutation();

  const handleSave = async (recipe_id: string) => {
    try {
      await addRecipeToMeal({ meal_id: mealId, recipe_id: recipe_id, multiplier: 1 }).unwrap();
      onClose();
    } catch (error) {
      console.error('Failed to add recipe to meal:', error);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent variant="wide">
        <DialogHeader>
          <DialogTitle>Create Recipe</DialogTitle>
        </DialogHeader>
        <div className="my-4 flex flex-col space-y-4 items-start">
          <CreateRecipe
            saveCallback={handleSave}
            initialMessage={initialMessage}
          />
        </div>
        <DialogFooter>
          <button 
            className="btn p-2 rounded bg-red-500 text-white border-none" 
            onClick={onClose}
          >
            Cancel
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CreateRecipeModal;