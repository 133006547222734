import React, { useEffect, useState } from 'react';
import { auth } from '../../firebase';
import AuthContext from './AuthContext';
import { onAuthStateChanged, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword } from 'firebase/auth'; // Import onAuthStateChanged

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const { uid, displayName, email, photoURL } = result.user;
      setCurrentUser({ uid, displayName, email, photoURL });
    } catch (error) {
      console.error(error);
    }
  };

  const signInWithEmail = async (email, password) => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      const { uid, displayName, email: userEmail, photoURL } = result.user;
      setCurrentUser({ uid, displayName, email: userEmail, photoURL });
    } catch (error) {
      console.error('Error signing in with email:', error);
      throw error; // Re-throw the error so it can be handled by the component calling this function
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <span className="loading loading-dots loading-lg"/>; // Or a loading spinner
  }

  return (
    <AuthContext.Provider value={{ currentUser, signInWithGoogle, signInWithEmail }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;