import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "src/lib/utils"

const buttonVariants = cva(
  'inline-flex text-black items-center text-black justify-center whitespace-nowrap rounded-base text-sm font-base ring-offset-white transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-black focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'bg-primary border border-border border-black shadow-light hover:translate-x-boxShadowX hover:translate-y-boxShadowY hover:shadow-none',
        noShadow: 'bg-primary border border-border border-black hover:bg-primary/80',
        link: 'underline-offset-4 text-primary hover:underline',
        darkLink: 'underline-offset-4 text-gray-600 hover:underline hover:text-gray-400 decoration-primary',
        neutral:
          'bg-gray-300 text-black border border-border border-black hover:translate-x-reverseBoxShadowX hover:translate-y-reverseBoxShadowY hover:shadow-light',
        dark: 'bg-gray-800 text-gray-300 border border-border border-gray-300 hover:translate-x-reverseBoxShadowX hover:translate-y-reverseBoxShadowY hover:shadow-dark-gray',
        reverse:
          'bg-primary border border-border border-black hover:translate-x-reverseBoxShadowX hover:translate-y-reverseBoxShadowY hover:shadow-light',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-8 px-1 text-xs', 
        lg: 'h-11 px-8 text-lg',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }