import React, { useState, useEffect, useRef } from 'react';
import { makeBackendRequest } from '../../api'; // todo convert to the apiSlice command but it's complicated
import RecipeItem from '../../components/RecipeItem';
import HorizontalRecipeItem from '../../components/HorizontalRecipeItem';
import FocusedTextInput from '../../components/FocusedTextInput';
import { formatDate } from '../../utils';
import { testRecipe } from '../../testData';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BASE_WS_URL } from '../../constants';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Freemode } from 'swiper';
import { useAddRecipeToMealMutation, useRemoveRecipeFromMealMutation } from 'src/store/apiSlice';
import { useSelector, useDispatch } from 'react-redux';
import { setSearchesForMeal, addSearchForMeal, removeSearchForMeal, updateRecipesForSearch, setMealIdea, toggleSearchDisplay, setSearchDisplay, onlyShowSearchForMeal } from './mealPlanSlice';
import AddRecipeToMeal from '../../components/AddRecipeToMeal';
import CreateRecipeModal from '../recipeGeneration/CreateRecipeModal';
import { current } from '@reduxjs/toolkit';
import AdjustQuantityModal from './AdjustQuantityModal';
import CreateYourOwnRecipeButton from './CreateYourOwnRecipeButton';
import { Button } from '../../components/Button';

const getRandomRecipeQuantities = (maxQuantity) => {
    const randomQuantity = Math.floor(Math.random() * (maxQuantity + 1)); // 0 to maxQuantity
    return Array.from({ length: randomQuantity }, () => ({ recipe: testRecipe, multiplier: 1 }));
};

const getRandomRecipes = (maxQuantity) => {
    const randomQuantity = Math.floor(Math.random() * (maxQuantity + 1)); // 0 to maxQuantity
    return Array.from({ length: randomQuantity }, () => testRecipe);
};

export default function ScheduledMeal({ meal, mealType }) {
    const dispatch = useDispatch();
    const searches = useSelector(state => state.mealPlan.mealSearches[meal.id] || []);
    const swiperRefs = useRef([]);
    const mealIdea = useSelector(state => state.mealPlan.mealIdeas[meal.id] || "");
    const ws = useRef(null);
    const addCardClassName = "bg-gray-800 text-gray-200 rounded-md shadow shadow-gray-500 p-2 text-sm w-48 border border-white m-2"
    const [addRecipeToMeal] = useAddRecipeToMealMutation();
    const [removeRecipeFromMeal] = useRemoveRecipeFromMealMutation();
    const [endOfStream, setEndOfStream] = useState(false);
    const [isAddRecipeModalOpen, setIsAddRecipeModalOpen] = useState(false);
    const [isCreateRecipeModalOpen, setIsCreateRecipeModalOpen] = useState(false);
    const [currentSearchTerm, setCurrentSearchTerm] = useState('');
    const displaySearches = useSelector(state => state.mealPlan.displaySearches[meal.id] ?? true);
    const [isAdjustQuantityModalOpen, setIsAdjustQuantityModalOpen] = useState(false);
    const [currentRecipeForAdjustment, setCurrentRecipeForAdjustment] = useState(null);

    useEffect(() => {
        const initializeSearches = async () => {
            if (endOfStream) {
                for (let index = 0; index < searches.length; index++) {
                    const search = searches[index];
                    await handleSearchChange(index, search.searchTerm); // Logic is necessary to return responses top down
                }
                setEndOfStream(false);
            }
        };
    
        initializeSearches();
    }, [endOfStream, searches]);

    const handleOpenAddRecipeModal = (mealId) => {
        setIsAddRecipeModalOpen(true);
    };

    const handleCloseAddRecipeModal = () => {
        setIsAddRecipeModalOpen(false);
    };

    const handleSearchChange = (index, value) => {
        if (value.length < 3) {
            console.log("Search term must be at least 3 characters long.");
            return;
        }
    
        const params = {
            search_term: value,
            max_values: 15
        };
    
        makeBackendRequest('/search_for_recipes', 'get', null, true, params)
            .then(response => {
                const newRecipes = response.data.recipes;
                dispatch(updateRecipesForSearch({ mealId: meal.id, index, recipes: newRecipes }));
            })
            .catch(error => {
                console.error("Failed to fetch recipes:", error);
            });
    };

    const handleAddRecipeToMeal = async (recipeId, multiplier = 1) => {
        if (!meal || !meal.id) {
            console.error("Meal ID is not available.");
            return;
        }
    
        const payload = {
            meal_id: meal.id,
            recipe_id: recipeId,
            multiplier: multiplier // Assuming a default multiplier of 1, adjust as necessary
        };
    
        await addRecipeToMeal(payload).unwrap();
    };

    const handleRemoveRecipeFromMeal = async (recipeId) => {
        if (!meal || !meal.id) {
            console.error("Meal ID is not available.");
            return;
        }
    
        const payload = {
            meal_id: meal.id,
            recipe_id: recipeId,
        };
    
        await removeRecipeFromMeal(payload).unwrap();
    };

    const handleRemoveSearch = (index) => {
        dispatch(removeSearchForMeal({ mealId: meal.id, index }));
    };

    const handleMealIdeaChange = (idea) => {
        dispatch(setMealIdea({ mealId: meal.id, idea }));
        if (idea.trim() !== "") {
            dispatch(onlyShowSearchForMeal({ mealId: meal.id }));
        }
    };

    useEffect(() => {
        if (mealIdea) {
            ws.current = new WebSocket(`${BASE_WS_URL}/generate_recipe_strings`);

            ws.current.onopen = () => {
                console.log('WebSocket is connected');
                ws.current.send(JSON.stringify({ payload: { meal_idea: mealIdea, meal_type: meal.meal_type } }));
            };

            ws.current.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.recipe_names) {
                    console.log("Recipe names");
                    dispatch(setSearchesForMeal({ mealId: meal.id, searches: data.recipe_names.map(name => ({ searchTerm: name, recipes: [] })) }));
                } else if (data.end_of_stream) {
                    setEndOfStream(true);
                }
            };

            ws.current.onerror = (error) => {
                console.log('WebSocket error:', error);
            };

            ws.current.onclose = () => {
                console.log('WebSocket is closed');
            };

            return () => {
                if (ws.current) {
                    ws.current.close();
                }
            };
        }
    }, [mealIdea, mealType]);

    const handleToggleSearchDisplay = () => {
        if (displaySearches) {
            // If currently displaying searches, toggle the display off
            dispatch(toggleSearchDisplay({ mealId: meal.id }));
        } else {
            // If currently not displaying searches, ensure only this meal's searches are shown
            dispatch(onlyShowSearchForMeal({ mealId: meal.id }));
        }
    };

    const renderSearches = () => {
        if (displaySearches) {
            return (
                <div className="w-3/4">
                    {searches.map((search, index) => (
                        <div key={`${meal.id}-${index}`} className="mb-2">
                            <div className="flex">
                                <div className="flex items-center">
                                    <FocusedTextInput 
                                        onFocusChange={(value) => handleSearchChange(index, value)} 
                                        key={`${meal.id}-${index}`} 
                                        value={search.searchTerm} 
                                        className='text-sm flex-grow'
                                    />
                                {search.recipes.length > 0 && (
                                    <div>
                                        {/* Adjusted navigation buttons */}
                                        <Button variant="dark" className="mx-2" onClick={() => swiperRefs.current[index].slidePrev()}>
                                            Back
                                        </Button>
                                        <Button variant="dark" onClick={() => swiperRefs.current[index].slideNext()}>
                                            Next
                                        </Button>
                                    </div>
                                )}
                                </div>
                            </div>
                            {search.recipes.length > 0 && (
                                <div className='py-4'>
                                    <Swiper
                                        slidesPerView={'auto'}
                                        spaceBetween={10}
                                        className='py-2'
                                        onSwiper={(swiper) => swiperRefs.current[index] = swiper} // Assign each Swiper instance to the corresponding ref
                                    >
                                        <SwiperSlide key={`${meal.id}-${index}-generate`} className='!w-auto'>
                                            <CreateYourOwnRecipeButton
                                                searchTerm={search.searchTerm}
                                                onClick={() => {
                                                    setCurrentSearchTerm(search.searchTerm);
                                                    setIsCreateRecipeModalOpen(true);
                                                }}
                                            />
                                        </SwiperSlide>
                                        {search.recipes.map((recipe, idx) => (
                                        <SwiperSlide key={`${meal.id}-${index}-${idx}`} className='!w-auto'>
                                            <RecipeItem 
                                                recipe={recipe}
                                                className='mb-2' // Add margin so you can see the hover shadaow
                                                actions={[
                                                    { actionText: 'Add to meal', actionHandler: () => handleAddRecipeToMeal(recipe.recipe_id, 1) },
                                                ]}
                                            />
                                        </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            );
        } else {
            return (
                <p className="w-3/4 text-gray-500 text-xs">
                    {searches.map(search => search.searchTerm).join(', ')}
                </p>
            );
        }
    };

    const renderMeal = (mealName, mealId, recipes) => (
        <div key={mealId} className="bg-black text-gray-500 p-4 rounded-lg">
            <div className="flex justify-between items-center pr-4">
                <div className="font-bold text-gray-300 capitalize w-1/4 text-right pr-4">
                    {mealName} {formatDate(meal.date)}
                </div>
                <div className="w-3/4 flex items-center justify-between">
                    <div className="flex-grow">
                        <FocusedTextInput onFocusChange={handleMealIdeaChange} key={`${mealId}-${meal.date}`} className="w-full" placeholder='Describe your meal' value={mealIdea}/>
                    </div>
                    {recipes.length > 2 && (
                        <div className="flex ml-auto">
                            <Button variant="dark" className="mx-2" onClick={() => swiperRefs.current[0]?.slidePrev()}>
                                Back
                            </Button>
                            <Button variant="dark" onClick={() => swiperRefs.current[0]?.slideNext()}>
                                Next
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            <div className="flex pt-2">
                <div className='w-1/4 pr-4 text-sm text-right'>Planned recipes</div>
                <div className="flex flex-wrap w-3/4">
                    <Swiper
                        spaceBetween={10}
                        slidesPerView='auto'
                        onSwiper={(swiper) => swiperRefs.current[0] = swiper} // Assign Swiper instance to swiperRefs
                        className="w-full"
                    >
                        <SwiperSlide key={`${mealId}-add-recipe` }className='max-w-[256px]'>
                            <Button onClick={handleOpenAddRecipeModal} variant="dark">
                                Add recipe from library
                            </Button>
                        </SwiperSlide>
                        {recipes.map(recipeQuantity => (
                            <SwiperSlide key={recipeQuantity.recipe.recipe_id} className="flex justify-center max-w-[256px]">
                                <div className='py-2 px-2'>
                                    <RecipeItem 
                                        className='max-w-[256px]'
                                        recipe={recipeQuantity.recipe}
                                        quantity={recipeQuantity.multiplier}
                                        actions={[
                                            { actionText: 'Delete', actionHandler: () => handleRemoveRecipeFromMeal(recipeQuantity.recipe.recipe_id) },
                                            { actionText: `Adjust quantity`, actionHandler: () => handleOpenAdjustQuantityModal(recipeQuantity) }
                                        ]}
                                        suppressProcessing={true}
                                    />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
            <div className="flex pt-2">
                <div className='w-1/4 pr-4 text-xs text-right'>
                    {/* Conditionally render the toggle button only if there are searches */}
                    {searches.length > 0 && (
                        <Button variant="darkLink" onClick={handleToggleSearchDisplay}>
                            {displaySearches ? 'Hide searches' : 'Show searches'}
                        </Button>
                    )}
                </div>
                {renderSearches()}
            </div>
        <AddRecipeToMeal
            mealId={meal.id}
            isOpen={isAddRecipeModalOpen}
            onClose={handleCloseAddRecipeModal}
        />
        <CreateRecipeModal
            mealId={meal.id}
            isOpen={isCreateRecipeModalOpen}
            initialMessage={currentSearchTerm}
            onClose={() => setIsCreateRecipeModalOpen(false)}
        />
        <AdjustQuantityModal
            isOpen={isAdjustQuantityModalOpen}
            onClose={() => setIsAdjustQuantityModalOpen(false)}
            onSubmit={handleAdjustQuantitySubmit}
            initialMultiplier={currentRecipeForAdjustment ? currentRecipeForAdjustment.multiplier : 1}
        />
        </div>
    );

    const handleOpenAdjustQuantityModal = (recipe) => {
        setCurrentRecipeForAdjustment(recipe);
        setIsAdjustQuantityModalOpen(true);
    };

    const handleAdjustQuantitySubmit = async (multiplier) => {
        if (currentRecipeForAdjustment) {
            await handleAddRecipeToMeal(currentRecipeForAdjustment.recipe_id, multiplier);
            setCurrentRecipeForAdjustment(null);
        }
    };

    if (!meal) {
        return renderMeal(mealType, null, []);
    }
    else {
        // Use the new function to get random recipes
        const randomRecipes = getRandomRecipeQuantities(0); // Pass the maximum number of recipes as 2
        return renderMeal(meal.name || meal.meal_type, meal.id, meal.recipes);
    }
}
