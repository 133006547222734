import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import localIcon from '../assets/cooking_icon.png';

// Helper function to parse ISO 8601 duration
const parseDuration = (isoDuration) => {
    const match = isoDuration.match(/PT(?:(\d+)H)?(?:(\d+)M)?/);
    if (!match) return null;

    const [, hours, minutes] = match;
    const totalMinutes = (parseInt(hours) || 0) * 60 + (parseInt(minutes) || 0);

    const days = Math.floor(totalMinutes / (24 * 60));
    const remainingHours = Math.floor((totalMinutes % (24 * 60)) / 60);
    const remainingMinutes = totalMinutes % 60;

    let result = '';
    if (days > 0) result += `${days}d`;
    if (remainingHours > 0) result += `${remainingHours}h`;
    if (remainingMinutes > 0) result += `${remainingMinutes}m`;

    return result || null;
};

export default function NewRecipeItem({ recipe, quantity = null, handleDelete = null, deleteText = 'Remove', className = '', actions = [], suppressProcessing = false}) {
    const faviconUrl = recipe.favicon;

    if (handleDelete) {
        actions.push({ actionText: deleteText, actionHandler: (id) => handleDelete(id) });
    }

    const quantityString = quantity && quantity !== 1 ? `${quantity}x ` : '';

    let detail_strings = [];

    if (recipe.aggreate_rating) {
        if (recipe.aggreate_rating.ratingValue && recipe.aggreate_rating.ratingCount) {
            const reviewText = recipe.aggreate_rating.ratingCount === 1 ? 'review' : 'reviews';
            detail_strings.push(`Rating: ${recipe.aggreate_rating.ratingValue} (${recipe.aggreate_rating.ratingCount} ${reviewText})`);
        } else if (recipe.aggreate_rating.ratingValue) {
            detail_strings.push(`Rating: ${recipe.aggreate_rating.ratingValue}`);
        }
    }

    if (recipe.parsed_ingredients) {
        const ingredientCount = recipe.parsed_ingredients.length;
        const ingredientText = ingredientCount === 1 ? 'ingredient' : 'ingredients';
        detail_strings.push(`${ingredientCount} ${ingredientText}`);
    }

    if (recipe.timing) {
        const { totalTime, cookTime } = recipe.timing;
        const time = totalTime || cookTime;
        if (time) {
            const parsedTime = parseDuration(time);
            if (parsedTime) {
                detail_strings.push(`Cook time: ${parsedTime}`);
            }
        }
    }

    const CardContent = () => (
        <div className={`${className} bg-gray-200 shadow-white p-2 rounded-md border border-black`}>
            <div className="flex items-start">
                <div className="flex-grow ml-2 flex flex-col justify-between">
                    <div>
                        <div className="text-md font-bold line-clamp-1 text-black">
                            {quantityString}{recipe.recipe_name}
                        </div>
                        <div className="shrink-0 flex pt-1">
                            <img src={faviconUrl || localIcon} onError={(e) => { e.target.onerror = null; e.target.src = localIcon; }} className="w-4 h-4" alt="Recipe Icon" />
                            <p className="ml-2 text-black text-xs">{recipe.domain_name}</p>
                        </div>
                        {detail_strings.length > 0 && (
                            <div className="text-sm text-black mt-2">
                                {detail_strings.join(" · ")}
                            </div>
                        )}
                        {detail_strings.length == 0 && (
                            <div className="text-sm line-clamp-2 text-gray-700 mt-2">
                                {recipe.description}
                            </div>
                        )}
                    </div>
                    <div className="flex justify-between w-full">
                        <div className="flex items-center">
                            {('processing_complete' in recipe && !recipe.processing_complete && recipe.processing_complete !== null && !suppressProcessing) && (
                                <div className="flex items-center mr-2">
                                    <p className="text-xs">Processing </p><span className="loading loading-dots loading-sm"></span>
                                </div>
                            )}
                        </div>
                        <div className="flex items-center space-x-2">
                            {actions.map((action, index) => (
                                <button key={index} onClick={(e) => { e.stopPropagation(); e.preventDefault(); action.actionHandler(recipe.id); }} className="text-xs hover:text-red-700 mt-2 self-end">
                                    {action.actionText}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return recipe.recipe_type === 'native' ? (
        <Link to={`/recipe/${recipe.recipe_id}`}>
            <CardContent />
        </Link>
    ) : (
        <a href={recipe.url} target="_blank" rel="noopener noreferrer">
            <CardContent />
        </a>
    );
}