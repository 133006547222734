import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

function Changelog() {
  const [changelog, setChangelog] = useState('');

  useEffect(() => {
    fetch('/changelog.md')
      .then(response => response.text())
      .then(text => setChangelog(text))
      .catch(error => console.error('Error fetching changelog:', error));
  }, []);

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Changelog</h1>
      <div className="prose prose-invert">
        <ReactMarkdown>{changelog}</ReactMarkdown>
      </div>
    </div>
  );
}

export default Changelog;