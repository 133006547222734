import React, { useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogClose } from '../../components/Dialog';
import { Button } from '../../components/Button';

const EditPreferenceDialog = ({ isOpen, onClose, preferenceKey, value, onSave, isAdding }) => {
  const [newValue, setNewValue] = React.useState(value);

  useEffect(() => {
    setNewValue(value);
  }, [value, isOpen]);

  const handleSave = () => {
    onSave(preferenceKey, newValue);
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{isAdding ? 'Add new sticky note' : 'Edit sticky note'}</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <textarea
            className="w-full h-32 p-2 border rounded"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
          />
        </DialogDescription>
        <div className="flex justify-end space-x-2 mt-4">
          <DialogClose asChild>
            <Button onClick={onClose}>Cancel</Button>
          </DialogClose>
          <Button onClick={handleSave}>Save</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditPreferenceDialog;