import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import { signOut } from 'firebase/auth';
import { Button } from '../../components/Button';
import { useGetUserQuery, useUpdateUserMutation } from '../../store/apiSlice';
import HouseholdMemberDialog from './HouseholdMemberDialog';
import PreferenceStickyNote from './PreferenceStickyNote';
import EditPreferenceDialog from './EditPreferenceDialog';
import { v4 as uuidv4 } from 'uuid'; // Make sure to install and import uuid

const Profile = () => {
  const navigate = useNavigate();
  const { data: user, isLoading, isError } = useGetUserQuery();
  const [updateUser] = useUpdateUserMutation();
  const [isHouseholdDialogOpen, setIsHouseholdDialogOpen] = useState(false);
  const [editingMember, setEditingMember] = useState(null);
  const [editingPreference, setEditingPreference] = useState(null);
  const [addingPreference, setAddingPreference] = useState(null);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddMember = (newMember) => {
    if (user && user.household) {
      const updatedHousehold = [...user.household, newMember];
      updateUser({ household: updatedHousehold });
    }
  };

  const handleEditMember = (member) => {
    setEditingMember(member);
    setIsHouseholdDialogOpen(true);
  };

  const handleUpdateMember = (updatedMember) => {
    if (user && user.household) {
      const updatedHousehold = user.household.map(member => 
        member.id === updatedMember.id ? updatedMember : member
      );
      updateUser({ household: updatedHousehold });
    }
    setEditingMember(null);
  };

  const handleDeleteMember = (memberId) => {
    if (user && user.household) {
      const updatedHousehold = user.household.filter(member => member.id !== memberId);
      updateUser({ household: updatedHousehold });
    }
  };

  const handleEditPreference = (category, id, preference) => {
    setEditingPreference({ category, id, preference });
    setAddingPreference(null);
  };

  const handleUpdatePreference = (category, id, newPreference) => {
    if (user && user.preferences && user.preferences[category]) {
      const updatedPreferences = {
        ...user.preferences,
        [category]: user.preferences[category].map(item => 
          item.id === id ? { ...item, preference: newPreference } : item
        )
      };
      updateUser({ preferences: updatedPreferences });
    }
  };

  const handleDeletePreference = (category, id) => {
    if (user && user.preferences && user.preferences[category]) {
      const updatedPreferences = {
        ...user.preferences,
        [category]: user.preferences[category].filter(item => item.id !== id)
      };
      updateUser({ preferences: updatedPreferences });
    }
  };

  const handleAddPreference = (category) => {
    setAddingPreference({ category, id: uuidv4(), preference: '' });
    setEditingPreference(null);
  };

  const handleSaveNewPreference = (category, newPreference) => {
    if (user && user.preferences) {
      const updatedPreferences = {
        ...user.preferences,
        [category]: [...(user.preferences[category] || []), { id: uuidv4(), preference: newPreference }]
      };
      updateUser({ preferences: updatedPreferences });
    }
    setAddingPreference(null);
  };

  const handleClosePreferenceDialog = () => {
    setEditingPreference(null);
    setAddingPreference(null);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError || !user) {
    return <div>Error loading user data</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-5xl">
      <div className="flex justify-between items-center mb-8">
        <h2>Profile</h2>
        <Button onClick={handleSignOut}>Sign Out</Button>
      </div>
      
      <section className="mb-8">
        <h3 className="mb-4">Your Household</h3>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-4"><strong>Name</strong></div>
          <div className="col-span-2"><strong>Calorie Target</strong></div>
          <div className="col-span-1"><strong>Carb %</strong></div>
          <div className="col-span-1"><strong>Fat %</strong></div>
          <div className="col-span-1"><strong>Protein %</strong></div>
          <div className="col-span-1"></div>
          <div className="col-span-2"></div>
          {user.household && user.household.map((member) => (
            <React.Fragment key={member.id}>
              <div className="col-span-4">{member.customer_facing_name || 'Not set'}</div>
              <div className="col-span-2">{member.calorie_target}</div>
              <div className="col-span-1">{(member.carb_percentage * 100).toFixed(0)}%</div>
              <div className="col-span-1">{(member.fat_percentage * 100).toFixed(0)}%</div>
              <div className="col-span-1">{(member.protein_percentage * 100).toFixed(0)}%</div>
              <div className="col-span-2">
                <Button onClick={() => handleEditMember(member)} variant="reverse" size="sm" className="bg-gray-300 mr-4">
                  Edit
                </Button>
                <Button onClick={() => handleDeleteMember(member.id)} variant="reverse" className="bg-destructive" size="sm">
                  Delete
                </Button>
              </div>
            </React.Fragment>
          ))}
        </div>
        <Button 
          onClick={() => {setEditingMember(null); setIsHouseholdDialogOpen(true);}} 
          className="mt-4"
        >
          Add Household Member
        </Button>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Your Preferences</h2>
        
        <h3 className="text-xl font-semibold mb-2">Recipe Generation</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-4">
          {user.preferences?.recipe_generation?.map(({ id, preference }) => (
            <PreferenceStickyNote
              key={id}
              preferenceKey={id}
              value={preference}
              onEdit={(_, value) => handleEditPreference('recipe_generation', id, value)}
              onDelete={() => handleDeletePreference('recipe_generation', id)}
            />
          ))}
          <Button onClick={() => handleAddPreference('recipe_generation')} className="w-32 bg-yellow">
            Add Sticky
          </Button>
        </div>

        <h3 className="text-xl font-semibold mb-2">Meal Planning Ideas</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {user.preferences?.meal_planning_ideas?.map(({ id, preference }) => (
            <PreferenceStickyNote
              key={id}
              preferenceKey={id}
              value={preference}
              onEdit={(_, value) => handleEditPreference('meal_planning_ideas', id, value)}
              onDelete={() => handleDeletePreference('meal_planning_ideas', id)}
            />
          ))}
          <Button onClick={() => handleAddPreference('meal_planning_ideas')} className="w-32 bg-yellow">
            Add Sticky
          </Button>
        </div>
      </section>

      <HouseholdMemberDialog
        isOpen={isHouseholdDialogOpen}
        onClose={() => {setIsHouseholdDialogOpen(false); setEditingMember(null);}}
        onSave={editingMember ? handleUpdateMember : handleAddMember}
        member={editingMember}
      />

      <EditPreferenceDialog
        isOpen={!!editingPreference || !!addingPreference}
        onClose={handleClosePreferenceDialog}
        preferenceKey={editingPreference?.id || addingPreference?.id}
        value={editingPreference?.preference ?? addingPreference?.preference ?? ''}
        onSave={(_, newValue) => {
          if (editingPreference) {
            handleUpdatePreference(editingPreference.category, editingPreference.id, newValue);
          } else if (addingPreference) {
            handleSaveNewPreference(addingPreference.category, newValue);
          }
          handleClosePreferenceDialog();
        }}
        isAdding={!!addingPreference}
      />
    </div>
  );
};

export default Profile;