// src/components/RecipeList.js
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../auth/AuthContext';
import { makeBackendRequest } from '../../api';
import RecipeItem from '../../components/RecipeItem';
import HorizontalRecipeItem from '../../components/HorizontalRecipeItem';
import AddExternalRecipe from './AddExternalRecipe';
import { useListRecipeLibraryQuery, useDeleteRecipeMutation, useRemoveRecipeFromQueueMutation, useAddRecipeToQueueMutation } from 'src/store/apiSlice';
import Masonry from 'react-masonry-css';

export default function RecipeList() {
    const [isAddExternalRecipeModalOpen, setIsAddExternalRecipeModalOpen] = useState(false);
    const [parentWidth, setParentWidth] = useState(1024); // Default to max-width
    const parentRef = useRef(null);
    const authContext = useContext(AuthContext);
    const currentUser = authContext.currentUser;
    const navigate = useNavigate();

    const { data: recipes, error, isLoading } = useListRecipeLibraryQuery();
    const [deleteRecipe] = useDeleteRecipeMutation();
    const [removeRecipeFromQueue] = useRemoveRecipeFromQueueMutation();
    const [addRecipeToQueue] = useAddRecipeToQueueMutation();

    useEffect(() => {
        const updateWidth = () => {
            if (parentRef.current) {
                setParentWidth(parentRef.current.offsetWidth);
            }
        };

        updateWidth(); // Initial measurement
        window.addEventListener('resize', updateWidth);

        return () => window.removeEventListener('resize', updateWidth);
    }, [navigate]);

    const handleDelete = async (recipeId) => {
        const recipeIds = { saved_recipe_ids: [recipeId] };
        await deleteRecipe(recipeIds);
    };

    const handleQueueToggle = async (recipeId, queued) => {
        if (queued) {
            await removeRecipeFromQueue(recipeId);
        } else {
            await addRecipeToQueue(recipeId);
        }
    };

    const calculateColumns = (containerWidth) => {
        const minItemWidth = 288;
        const maxItemWidth = 320;
        const padding = 16; // Assuming 16px padding between items
        const availableWidth = containerWidth - padding; // Subtracting padding from the container width
        
        const minColumns = Math.floor(availableWidth / (maxItemWidth + padding));
        const maxColumns = Math.floor(availableWidth / (minItemWidth + padding));
        
        return Math.max(1, Math.min(minColumns, maxColumns));
    };

    const breakpointColumnsObj = {
        default: calculateColumns(parentWidth),
        1024: calculateColumns(1024),
        768: calculateColumns(768),
        640: calculateColumns(640),
        480: calculateColumns(480),
    };

    const renderRecipeSection = (recipes, title) => {
        if (recipes.length === 0) return null;

        return (
            <>
                {title && <h3 className="text-2xl font-bold mb-4">{title}</h3>}
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                >
                    {recipes.map(recipe => (
                        <div key={recipe.id} className="recipe-item">
                            <RecipeItem
                                recipe={recipe}
                                className='max-w-full'
                                actions={[
                                    { 
                                        actionText: recipe.queued ? 'Remove from queue' : 'Add to queue', 
                                        actionHandler: () => handleQueueToggle(recipe.id, recipe.queued) 
                                    },
                                    { actionText: 'Delete', actionHandler: () => handleDelete(recipe.id) }
                                ]}
                            />
                        </div>
                    ))}
                </Masonry>
            </>
        );
    };

    return (
        <div className="max-w-[1024px] mx-auto px-8" ref={parentRef}>
            <div className="masonry-wrapper">
                <div className="flex flex-wrap justify-between items-end pt-16 pb-4 relative">
                    <h1 className="text-4xl font-bold">
                        Library
                    </h1>
                    <div className="dropdown dropdown-bottom dropdown-hover dropdown-end mb-4">
                        <label tabIndex={0} className="btn bg-gray-900 text-gray-500 border-gray-500">
                            <i className="fas fa-plus"></i>
                        </label>
                        <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-gray-200 text-black rounded-box w-52">
                            <li>
                                <button onClick={() => setIsAddExternalRecipeModalOpen(true)} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                    Add External Recipe
                                </button>
                            </li>
                            <li>
                                <Link to="/create_recipe" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                    Create New Recipe
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                {isLoading && <span className="loading loading-dots loading-lg"/>}
        
                {recipes && (
                    <>
                        {recipes.some(recipe => recipe.queued) ? (
                            <>
                                {renderRecipeSection(recipes.filter(recipe => recipe.queued), 'Queued Recipes')}
                                {renderRecipeSection(recipes.filter(recipe => !recipe.queued), 'All Other Recipes')}
                            </>
                        ) : (
                            renderRecipeSection(recipes, null)
                        )}
                    </>
                )}
        
                <AddExternalRecipe isOpen={isAddExternalRecipeModalOpen} onRequestClose={() => setIsAddExternalRecipeModalOpen(false)} />
            </div>
        </div>
    );
}