import React from 'react';
import Card from '../../components/Card';

const CookModeStep = ({ stepNumber, stepDescription, duration, selected, color='purple', requiredResources, remainingTime }) => {
    const hasAutomaticTimer = requiredResources && 'person' in requiredResources;

    if (selected) {
        return (
            <Card className={`w-full max-w-[640px] ${selected ? `bg-${color}-100 hover:bg-${color}-200` : ''}`} boxShadowColor={selected ? color : 'transparent'} alwaysShowShadow={selected}>
                <div className="flex flex-col">
                    <div className="flex justify-between items-center mb-2">
                        <span className={`text-sm text-${color}`}>Step {stepNumber}</span>
                        <span className={`text-sm text-${color}`}>{duration}</span>
                    </div>
                    <p className={`text-lg font-bold`}>{stepDescription}</p>
                </div>
            </Card>
        );
    } else {
        return (
            <div className="w-full max-w-[640px] py-1">
                <p className={`text-base text-${color}`}>• {stepDescription}</p>
            </div>
        );
    }
};

export default CookModeStep;

