import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetMealQuery } from 'src/store/apiSlice';
import RecipeItem from '../../components/RecipeItem';
import ColoredButton from '../../components/ColoredButton';

export default function Meal() {
    const { meal_id } = useParams();
    const { data: meal, isLoading, isError, refetch } = useGetMealQuery(meal_id);


    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error loading meal data</div>;

    return (
        <div className="flex flex-col items-center p-4">
            <h1 className="text-2xl font-bold mb-4">{meal.meal_type} for {meal.date}</h1>
            
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-6">
                {meal.recipes.map((recipeQuantity) => (
                    <RecipeItem 
                        key={recipeQuantity.recipe.id} 
                        recipe={recipeQuantity.recipe} 
                        quantity={recipeQuantity.multiplier}
                    />
                ))}
            </div>
        </div>
    );
}