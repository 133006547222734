import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mealSearches: {},
  runHistory: [],
  mealIdeas: {}, // State to store meal ideas
  displaySearches: {}, // New state to store display toggles for searches
};

const mealPlanSlice = createSlice({
  name: 'mealPlan',
  initialState,
  reducers: {
    setSearchesForMeal: (state, action) => {
      const { mealId, searches } = action.payload;
      state.mealSearches[mealId] = searches;
    },
    addSearchForMeal: (state, action) => {
      const { mealId, searchTerm } = action.payload;
      if (!state.mealSearches[mealId]) {
        state.mealSearches[mealId] = [];
      }
      state.mealSearches[mealId].push({ searchTerm, recipes: [] });
    },
    removeSearchForMeal: (state, action) => {
      const { mealId, index } = action.payload;
      if (state.mealSearches[mealId]) {
        state.mealSearches[mealId].splice(index, 1);
      }
    },
    updateRecipesForSearch: (state, action) => {
      const { mealId, index, recipes } = action.payload;
      if (state.mealSearches[mealId] && state.mealSearches[mealId][index]) {
        state.mealSearches[mealId][index].recipes = recipes;
      }
    },
    addRunHistory: (state, action) => {
      state.runHistory.push(action.payload);
    },
    setMealIdea: (state, action) => {
      const { mealId, idea } = action.payload;
      state.mealIdeas[mealId] = idea;
    },
    toggleSearchDisplay: (state, action) => {
      const { mealId } = action.payload;
      // Toggle the display state, defaulting to true if not already set
      state.displaySearches[mealId] = !state.displaySearches[mealId];
    },
    setSearchDisplay: (state, action) => {
      const { mealId, display } = action.payload;
      state.displaySearches[mealId] = display;
    },
    initializeDisplaySearches: (state, action) => {
      action.payload.forEach((meal, index) => {
        if (state.displaySearches[meal.id] === undefined) {
          state.displaySearches[meal.id] = index === 0;
        }
      });
    },
    onlyShowSearchForMeal: (state, action) => {
      const { mealId } = action.payload;
      Object.keys(state.displaySearches).forEach(id => {
        state.displaySearches[id] = false;
      });
      state.displaySearches[mealId] = true;
    },
  },
});

export const {
  setSearchesForMeal,
  addSearchForMeal,
  removeSearchForMeal,
  updateRecipesForSearch,
  addRunHistory,
  setMealIdea,
  toggleSearchDisplay,
  setSearchDisplay,
  initializeDisplaySearches,
  onlyShowSearchForMeal,
} = mealPlanSlice.actions;
export default mealPlanSlice.reducer;
