import React, { useState, useEffect, useMemo, useRef } from 'react';
import CookModeStep from './CookModeStep';
import { Button } from '../../components/Button';

const COLORS = ['purple', 'green', 'orange', 'blue', 'yellow'];

const CookModeProto = () => {
  const [steps, setSteps] = useState([]);
  const [selectedStep, setSelectedStep] = useState(0);
  const [remainingTime, setRemainingTime] = useState(null);
  const timerRef = useRef(null);

  const stepRefs = useRef({});
  const containerRef = useRef(null);

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/example_steps.json`)
      .then(response => response.json())
      .then(data => setSteps(data))
      .catch(error => console.error('Error loading steps:', error));
  }, []);

  const { groupedSteps, recipeColorMap } = useMemo(() => {
    const colorMap = {};
    let colorIndex = 0;

    const grouped = steps.reduce((acc, step, index) => {
      if (!colorMap[step.recipe_id]) {
        colorMap[step.recipe_id] = COLORS[colorIndex % COLORS.length];
        colorIndex++;
      }

      const lastGroup = acc[acc.length - 1];
      if (lastGroup && lastGroup.recipe_id === step.recipe_id) {
        lastGroup.steps.push({ ...step, index });
      } else {
        acc.push({
          recipe_id: step.recipe_id,
          recipe_name: step.recipe_name,
          steps: [{ ...step, index }],
        });
      }
      return acc;
    }, []);

    return { groupedSteps: grouped, recipeColorMap: colorMap };
  }, [steps]);

  const handlePrevious = () => {
    setSelectedStep((prev) => Math.max(0, prev - 1));
  };

  const handleNext = () => {
    setSelectedStep((prev) => Math.min(steps.length - 1, prev + 1));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowLeft') {
      handlePrevious();
    } else if (event.key === 'ArrowRight') {
      handleNext();
    }
  };

  const handleStepClick = (index) => {
    setSelectedStep(index);
  };

  useEffect(() => {
    const selectedStepElement = stepRefs.current[selectedStep];
    if (selectedStepElement && containerRef.current) {
      selectedStepElement.scrollIntoView({
        behavior: 'auto',
        block: 'center',
      });
    }
  }, [selectedStep]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [steps]);

  const startAutomaticTimer = (duration) => {
    clearInterval(timerRef.current);
    const totalSeconds = Math.round(duration * 60);
    setRemainingTime(totalSeconds);

    timerRef.current = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerRef.current);
          handleNext();
          return null;
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    const currentStep = steps[selectedStep];
    if (currentStep && currentStep.required_resources && 'person' in currentStep.required_resources) {
      startAutomaticTimer(currentStep.duration);
    } else {
      clearInterval(timerRef.current);
      setRemainingTime(null);
    }

    return () => clearInterval(timerRef.current);
  }, [selectedStep, steps]);

  const formatTime = (seconds) => {
    if (seconds === null) return '';
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="flex flex-col items-center p-8 min-h-screen">
      <h1 className="text-4xl font-bold text-gray-800 mb-8">Cook Mode</h1>
      <div className="w-full max-w-[640px] mb-6 flex justify-between items-end">
        <Button
          onClick={handlePrevious}
          disabled={selectedStep === 0}
          className="px-4 py-2 bg-blue-500 text-white rounded-md disabled:bg-gray-300 disabled:cursor-not-allowed"
        >
          Previous
        </Button>
        <div className="flex flex-col items-center">
          <span className="text-lg font-semibold mb-2">{formatTime(remainingTime)}</span>
          <Button
            onClick={handleNext}
            disabled={selectedStep === steps.length - 1}
            className="px-4 py-2 bg-blue-500 text-white rounded-md disabled:bg-gray-300 disabled:cursor-not-allowed"
          >
            Next
          </Button>
        </div>
      </div>
      <div className="w-full max-w-[640px] overflow-hidden h-[calc(100vh-200px)]">
        <div 
          ref={containerRef} 
          className="h-full overflow-y-scroll pr-[17px] mr-[-17px]"
        >
          {groupedSteps.map((group, groupIndex) => (
            <div key={`${group.recipe_id}-${groupIndex}`} className="mb-8">
              <h2 className={`text-2xl font-semibold text-${recipeColorMap[group.recipe_id]}`}>
                {group.recipe_name}
              </h2>
              {group.steps.map((step) => (
                <div
                  key={step.index}
                  ref={(el) => stepRefs.current[step.index] = el}
                  onClick={() => handleStepClick(step.index)}
                  className="cursor-pointer"
                >
                  <CookModeStep
                    stepNumber={step.index + 1}
                    stepDescription={step.description}
                    duration={`${step.duration} minutes`}
                    selected={step.index === selectedStep}
                    color={recipeColorMap[group.recipe_id]}
                    requiredResources={step.required_resources}
                    remainingTime={formatTime(remainingTime)}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CookModeProto;
