import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useGetMealPlanQuery, useListMealsQuery, useCreateGroceryListMutation, usePublishMealsMutation } from 'src/store/apiSlice';
import ScheduledMealPlanMeal from './ScheduledMealPlanMeal';
import { setMealIdea, addRunHistory, initializeDisplaySearches, onlyShowSearchForMeal } from './mealPlanSlice';
import { BASE_WS_URL } from '../../constants';
import FeedbackModal from './FeedbackModal';
import PollForIngredientModal from './PollForIngredientModal';
import { Button } from '../../components/Button';

function MealPlan() {
    const navigate = useNavigate();
    const [surprisePressed, setSurprisePressed] = useState(false);
    const { meal_plan_id } = useParams();
    const { data: mealPlan, error: mealPlanError, isLoading: mealPlanLoading } = useGetMealPlanQuery(meal_plan_id);
    const { data: mealsData, error: mealsError, isLoading: mealsLoading } = useListMealsQuery({ mealPlanId: meal_plan_id });
    const meals = mealsData ? mealsData.meals : [];
    const dispatch = useDispatch();
    const ws = useRef(null);
    const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
    const mealIdeas = useSelector(state => state.mealPlan.mealIdeas);
    const runHistory = useSelector(state => state.mealPlan.runHistory || []);
    const hasGroceryList = mealPlan && Boolean(mealPlan.grocery_list_id);
    const [pollModalOpen, setPollModalOpen] = useState(false);
    console.log(meals);
    const allMealsHaveRecipes = meals && meals.every(meal => meal.recipes && meal.recipes.length > 0);
    const someMealsHaveRecipes = meals && meals.some(meal => meal.recipes && meal.recipes.length > 0);

    const [publishMeals, { isLoading: isPublishingMeals }] = usePublishMealsMutation();
    const [createGroceryList, { isLoading: isCreatingGroceryList }] = useCreateGroceryListMutation();
    
    const handleCreateGroceryList = async () => {
        const mealIds = meals.map(meal => meal.id);
        const publishResult = await publishMeals(mealIds);
        if (publishResult.error) {
            console.error('Failed to publish meals:', JSON.stringify(publishResult.error, null, 2));
            return; // Stop the process if publishing fails
        }

        const result = await createGroceryList({ meal_ids: mealIds, meal_plan_id: meal_plan_id });

        if (result.error) {
            console.error('Error creating grocery list:', JSON.stringify(result.error, null, 2));
            if (result.error.status === 400 && result.error.data && result.error.data.detail.includes("Couldn't find parsed ingredients")) {
                setPollModalOpen(true); // Open the modal on this specific error
            }
        } else if (result.data && result.data.grocery_list_id) {
            navigate(`/grocery_list/${result.data.grocery_list_id}`);
        }
    };

    const retryCreateGroceryList = async () => {
        const mealIds = meals.map(meal => meal.id);
        const result = await createGroceryList({ meal_ids: mealIds, meal_plan_id: meal_plan_id });

        if (result.error) {
            console.error('Retry error in creating grocery list:', JSON.stringify(result.error, null, 2));
            if (result.error.status === 400 && result.error.data && result.error.data.detail.includes("Couldn't find parsed ingredients")) {
                // If still failing after max retries, handle accordingly in the modal
                throw new Error("Failed to create grocery list after retries.");
            }
            throw result.error; // Rethrow the error to be caught by the modal's error handling
        } else if (result.data && result.data.grocery_list_id) {
            setPollModalOpen(false); // Close the modal on success
            navigate(`/grocery_list/${result.data.grocery_list_id}`); // Navigate to the grocery list
        }
    };

    const handleNavigateToGroceryList = () => {
        navigate(`/grocery_list/${mealPlan.grocery_list_id}`);
    };


    useEffect(() => {
        if (meals && meals.length > 0) {
            dispatch(initializeDisplaySearches(meals));
        }
    }, [dispatch, meals]);

    const handleSurpriseMe = useCallback(() => {
        dispatch(onlyShowSearchForMeal({ mealId: meals[0].id }));
        if (surprisePressed) {
            setFeedbackModalOpen(true);
        } else {
            setSurprisePressed(true);
            initiateWebSocketConnection([]);
        }
    }, [surprisePressed, meals, dispatch]);

    const handleFeedbackSubmit = useCallback(async (feedback) => {
        const newRunInfo = {
            meal_ideas: Object.values(mealIdeas),
            user_feedback: feedback
        };
        await dispatch(addRunHistory(newRunInfo));
        setFeedbackModalOpen(false);
        
        // Use the latest run history directly instead of relying on the state update
        const updatedRunHistory = [...runHistory, newRunInfo];
        initiateWebSocketConnection(updatedRunHistory);
    }, [dispatch, mealIdeas, runHistory]);

    const initiateWebSocketConnection = useCallback((latestRunHistory) => {
        if (ws.current) {
            ws.current.close();
            ws.current = null;
        }

        ws.current = new WebSocket(`${BASE_WS_URL}/generate_meal_ideas`);
        ws.current.onopen = () => {
            console.log('WebSocket is connected');
            const payload = {
                target_meals: meals.map(meal => ({ meal_type: meal.meal_type, meal_id: meal.id })),
                run_history: latestRunHistory, // Use the passed-in run history
            };
            ws.current.send(JSON.stringify({ payload }));
        };

        ws.current.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.end_of_stream) {
                console.log('End of stream received, closing WebSocket.');
                ws.current.close();
                ws.current = null;
            } else {
                Object.entries(data).forEach(([mealId, idea]) => {
                    dispatch(setMealIdea({ mealId, idea }));
                });
            }
        };

        ws.current.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        ws.current.onclose = () => {
            console.log('WebSocket is closed');
            ws.current = null;
        };
    }, [meals]);

    if (mealPlanLoading || mealsLoading) return <div>Loading...</div>;
    if (mealPlanError) return <div>Error: {mealPlanError.message}</div>;
    if (mealsError) return <div>Error: {mealsError.message}</div>;

    return (
        <div className="max-w-[1280px] justify-center mx-auto px-4">
            <div className='flex justify-between items-center'>
                <h1 className="text-4xl">Build Meal Plan</h1>
                <div className="flex">
                    <Button variant={allMealsHaveRecipes ? 'link' : 'default'} className="mr-4" onClick={handleSurpriseMe}>
                        {surprisePressed ? "More ideas" : "Surprise me"}
                    </Button>
                    {someMealsHaveRecipes && (
                        hasGroceryList ? (
                            <Button variant={allMealsHaveRecipes ? 'default' : 'link'} onClick={handleNavigateToGroceryList}>
                                Go to Grocery List
                            </Button>
                        ) : (
                            <Button variant={allMealsHaveRecipes ? 'default' : 'link'} onClick={handleCreateGroceryList} disabled={isCreatingGroceryList}>
                                Create Grocery List
                            </Button>
                        )
                    )}
                </div>
            </div>
            {meals && meals.map((meal, index) => (
                <ScheduledMealPlanMeal key={index} meal={meal} />
            ))}
            <FeedbackModal
                isOpen={feedbackModalOpen}
                onClose={() => setFeedbackModalOpen(false)}
                onSubmitFeedback={handleFeedbackSubmit}
            />
            <PollForIngredientModal
                isOpen={pollModalOpen}
                onClose={() => setPollModalOpen(false)}
                onRetry={retryCreateGroceryList}
            />
        </div>
    );
}

export default MealPlan;

