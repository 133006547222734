import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { formatDate } from '../../utils';
import { Checkbox } from 'src/components/Checkbox';

function BuildSchedule({ schedule, updateSchedule, household }) {

  const handleCheckboxChange = (mealId, userId, isChecked) => {
    updateSchedule(mealId, userId, isChecked);
  };

  const toggleEveryone = (mealId, isChecked) => {
    household.forEach(member => {
      handleCheckboxChange(mealId, member.user_id, isChecked);
    });
  };

  const mealOrder = ['breakfast', 'lunch', 'snack', 'dinner'];

  const sortedSchedule = Object.values(schedule).sort((a, b) => {
    return new Date(a.date) - new Date(b.date) || 
           mealOrder.indexOf(a.meal_type) - mealOrder.indexOf(b.meal_type);
  });

  const groupedSchedule = sortedSchedule.reduce((acc, meal) => {
    const date = new Date(meal.date).toLocaleDateString();
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(meal);
    return acc;
  }, {});

  return (
    <div>
      <h3>Define your meals</h3>
      <table className='w-full'>
      <thead>
        <tr>
        <th className='text-right p-2'></th>
        <th className='p-2'>Everyone</th>
        {household.map(member => (
            <th className='p-2' key={member.user_id}>{member.first_name} {member.last_name}</th>
        ))}
        </tr>
      </thead>
        {Object.entries(groupedSchedule).map(([date, meals]) => (
          <tbody key={uuidv4()}>
            <tr>
              <td className='text-right text-center'>
                <h2 className="text-xl font-bold text-gray-300">
                    {formatDate(new Date(date))}
                </h2>
              </td>
            </tr>
            {meals.map(meal => (
              <tr key={uuidv4()} className="p-4">
                <td className='text-right text-gray-500 text-center'>{meal.meal_type.charAt(0).toUpperCase() + meal.meal_type.slice(1)}</td>
                <td className='text-center text-center'>
                  <Checkbox
                    className="border-white"
                    checked={Object.values(meal.users).every(Boolean)}
                    onCheckedChange={(checked) => toggleEveryone(meal.id, checked)}
                  />
                </td>
                {household.map(member => (
                  <td key={member.user_id} className='text-center text-center p-2'>
                    <Checkbox
                      className="border-white"
                      checked={meal.users[member.user_id]}
                      onCheckedChange={(checked) => handleCheckboxChange(meal.id, member.user_id, checked)}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        ))}
      </table>
    </div>
  );
}

export default BuildSchedule;