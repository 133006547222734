import React, { useState, useCallback } from 'react';

export default function ToggleableCard({ children, className = '', handleToggle }) {
    const [isToggled, setIsToggled] = useState(false);

    const toggleCard = useCallback(() => {
        const newToggleState = !isToggled;
        setIsToggled(newToggleState);
        handleToggle(newToggleState);
    }, [isToggled, handleToggle]);

    const toggleClasInfo = (
        isToggled ?
            'bg-green text-black hover:bg-green hover:text-gray-200 border-black' :
            'bg-black text-white hover:bg-gray-800 hover:text-gray-200 border-white'
    )

    return (
        <div 
            className={`
                flex p-2 ${className}
                ${toggleClasInfo}
                border rounded shadow-lg shadow-offset-white-right-down 
                hover:bg-gray-100 hover:text-black
                cursor-pointer transition-colors duration-200
            `}
            style={{ boxShadow: '5px 5px 0px 0px white' }}
            onClick={toggleCard}
        >
            {children}
        </div>
    );
}