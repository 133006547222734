import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../auth/AuthContext';
import { useParams } from 'react-router-dom';
import CreateRecipe from './CreateRecipe';


export default function CreateRecipePage() {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const currentUser = authContext.currentUser;
    const { generated_recipe_uuid } = useParams();
    const isEditMode = Boolean(generated_recipe_uuid);

    const handleSave = (recipe_id, generated_recipe_uuid) => {
        console.log("Redirecting to", recipe_id);
        navigate(`/recipe/${generated_recipe_uuid}`);
    };

    const createRecipe = (
        isEditMode ?
        <CreateRecipe saveCallback={handleSave} generated_recipe_uuid={generated_recipe_uuid}/> :
        <CreateRecipe saveCallback={handleSave}/>
    );

    return (
        <div className="max-w-[800px] justify-center mx-auto px-4">
            {createRecipe}
        </div>
    );
}