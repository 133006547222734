import React, { useState, useEffect, useMemo } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from 'src/components/Dialog';
import { Button } from 'src/components/Button';
import { Input } from 'src/components/Input';
import { v4 as uuidv4 } from 'uuid';
import { Label } from 'src/components/Label';
import { Badge } from 'src/components/Badge';

interface HouseholdMember {
  id: string;
  customer_facing_name: string;
  carb_percentage: number;
  fat_percentage: number;
  protein_percentage: number;
  calorie_target: number;
}

interface HouseholdMemberDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (member: HouseholdMember) => void;
  member?: HouseholdMember;
}

const HouseholdMemberDialog: React.FC<HouseholdMemberDialogProps> = ({ isOpen, onClose, onSave, member }) => {
  const [formData, setFormData] = useState<HouseholdMember>({
    id: '',
    customer_facing_name: '',
    carb_percentage: 0,
    fat_percentage: 0,
    protein_percentage: 0,
    calorie_target: 0,
  });

  useEffect(() => {
    if (member) {
      setFormData(member);
    } else {
      setFormData({
        id: uuidv4(),
        customer_facing_name: '',
        carb_percentage: 0,
        fat_percentage: 0,
        protein_percentage: 0,
        calorie_target: 0,
      });
    }
  }, [member]);

  const percentageSum = useMemo(() => {
    return formData.carb_percentage + formData.fat_percentage + formData.protein_percentage;
  }, [formData.carb_percentage, formData.fat_percentage, formData.protein_percentage]);

  const isPercentageValid = Math.abs(percentageSum - 1) < 0.001; // Allow for small floating-point errors

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: name === 'customer_facing_name' ? value : parseFloat(value),
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(formData);
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{member ? 'Edit' : 'Add'} Household Member</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit}>
          <DialogDescription>
            <div className="space-y-4">
              <div>
                <Label htmlFor="customer_facing_name">Name</Label>
                <Input
                  id="customer_facing_name"
                  name="customer_facing_name"
                  value={formData.customer_facing_name}
                  onChange={handleChange}
                  placeholder="Name"
                  required
                />
              </div>
              <div>
                <Label htmlFor="calorie_target">Calorie Target</Label>
                <Input
                  id="calorie_target"
                  name="calorie_target"
                  type="number"
                  value={formData.calorie_target}
                  onChange={handleChange}
                  placeholder="Calorie Target"
                  required
                />
              </div>
              <div>
                <Label htmlFor="carb_percentage">Carb Percentage</Label>
                <Input
                  id="carb_percentage"
                  name="carb_percentage"
                  type="number"
                  step="0.05"
                  min="0"
                  max="1"
                  value={formData.carb_percentage}
                  onChange={handleChange}
                  placeholder="Carb Percentage (0-1)"
                  required
                />
              </div>
              <div>
                <Label htmlFor="fat_percentage">Fat Percentage</Label>
                <Input
                  id="fat_percentage"
                  name="fat_percentage"
                  type="number"
                  step="0.05"
                  min="0"
                  max="1"
                  value={formData.fat_percentage}
                  onChange={handleChange}
                  placeholder="Fat Percentage (0-1)"
                  required
                />
              </div>
              <div>
                <Label htmlFor="protein_percentage">Protein Percentage</Label>
                <Input
                  id="protein_percentage"
                  name="protein_percentage"
                  type="number"
                  step="0.05"
                  min="0"
                  max="1"
                  value={formData.protein_percentage}
                  onChange={handleChange}
                  placeholder="Protein Percentage (0-1)"
                  required
                />
              </div>
              {!isPercentageValid && (
                <div className="flex items-center justify-between">
                  <span>Percentage Total: {(percentageSum * 100).toFixed(2)}%</span>
                  <Badge variant="destructive">
                    Percentages must add up to 100%
                  </Badge>
                </div>
              )}
            </div>
          </DialogDescription>
          <DialogFooter>
            <Button type="submit" disabled={!isPercentageValid}>Save</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default HouseholdMemberDialog;