import React, { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from './Dialog';
import { useAddRecipeToMealMutation, useListRecipeLibraryQuery } from 'src/store/apiSlice';
import { Button } from './Button';
import { Select, SelectGroup, SelectValue, SelectTrigger, SelectContent, SelectLabel, SelectItem, SelectSeparator } from './Select';

const AddRecipeToMeal = ({ mealId, isOpen, onClose }) => {
    const [selectedRecipe, setSelectedRecipe] = useState('');
    const [multiplier, setMultiplier] = useState('1');
    const { data: recipeLibrary, isLoading, isError } = useListRecipeLibraryQuery();
    const [addRecipeToMeal] = useAddRecipeToMealMutation();

    const options = [
        {
            label: "Queued Recipes",
            options: recipeLibrary?.filter(recipe => recipe.queued).map(recipe => ({
                value: recipe.recipe_id,
                label: recipe.recipe_name
            }))
        },
        {
            label: "All Other Recipes",
            options: recipeLibrary?.filter(recipe => !recipe.queued).map(recipe => ({
                value: recipe.recipe_id,
                label: recipe.recipe_name
            }))
        }
    ];

    const handleChange = (value) => {
        setSelectedRecipe(value);
    };

    const handleSubmitRecipe = async () => {
        try {
            await addRecipeToMeal({ meal_id: mealId, recipe_id: selectedRecipe, multiplier: parseFloat(multiplier) }).unwrap();
            onClose();
        } catch (error) {
            console.error('Failed to add recipe to meal:', error);
        }
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="bg-black text-white">
                <DialogHeader>
                    <DialogTitle>Add a recipe</DialogTitle>
                </DialogHeader>
                <div className="my-4 flex flex-col space-y-4 items-start">
                    {isLoading ? (
                        <div>Loading recipes...</div>
                    ) : isError ? (
                        <div>Error loading recipes. Please try again later.</div>
                    ) : (
                        <>
                            <label className="form-control w-full max-w-xs">
                                <div className="label">
                                    <span className="label-text text-white">Recipe</span>
                                </div>
                                <Select value={selectedRecipe} onValueChange={handleChange}>
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select a recipe" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            <SelectLabel>Queued Recipes</SelectLabel>
                                            {recipeLibrary?.filter(recipe => recipe.queued).map(recipe => (
                                                <SelectItem key={recipe.recipe_id} value={recipe.recipe_id}>
                                                    {recipe.recipe_name}
                                                </SelectItem>
                                            ))}
                                        </SelectGroup>
                                        <SelectSeparator />
                                        <SelectGroup>
                                            <SelectLabel>All Other Recipes</SelectLabel>
                                            {recipeLibrary?.filter(recipe => !recipe.queued).map(recipe => (
                                                <SelectItem key={recipe.recipe_id} value={recipe.recipe_id}>
                                                    {recipe.recipe_name}
                                                </SelectItem>
                                            ))}
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            </label>
                            <label className="form-control w-full max-w-xs">
                                <div className="label">
                                    <span className="label-text text-white">Amount</span>
                                </div>
                                <Select value={multiplier} onValueChange={setMultiplier}>
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select amount" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="0.5">0.5</SelectItem>
                                        <SelectItem value="1">1</SelectItem>
                                        <SelectItem value="2">2</SelectItem>
                                        <SelectItem value="3">3</SelectItem>
                                    </SelectContent>
                                </Select>
                            </label>
                        </>
                    )}
                </div>
                <DialogFooter>
                    <Button onClick={onClose} variant="secondary">Cancel</Button>
                    <Button onClick={handleSubmitRecipe}>Submit</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default AddRecipeToMeal;