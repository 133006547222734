// api.js
import axios from 'axios';
import { getAuth } from "firebase/auth";
import { BASE_API_URL } from './constants';

export const makeAuthenticatedRequest = async (url, method, data, params = {}) => {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    const config = {
        method: method,
        url: url,
        headers: {
            Authorization: `Bearer ${token}`
        },
        data: data,
        params: params // Params are now optional
    };
    return axios(config);
};

export const makeUnauthenticatedBackendRequest = async (endpoint, method, data, alertOnError = false, params = {}) => {
    const url = `${BASE_API_URL}${endpoint}`;
    try {
        const response = await axios({ method, url, data, params });
        if (response.status === 200) {
            return response;
        } else if (alertOnError) {
            const errorMessage = response.data.detail || response.data;
            alert(`Request failed with status code ${response.status}: ${errorMessage}`);
        }
    } catch (error) {
        if (alertOnError) {
            const errorMessage = error.response?.data?.detail || error.message;
            console.log(error.response?.data);
            alert(`Error: ${errorMessage}`);
        }
    }
};

export const makeBackendRequest = async (endpoint, method, data, alertOnError = false, params = {}) => {
    const url = `${BASE_API_URL}${endpoint}`;
    console.log(url)
    try {
        const response = await makeAuthenticatedRequest(url, method, data, params);
        if (response.status === 200) {
            return response;
        } else if (alertOnError) {
            const errorMessage = response.data.detail || response.data;
            alert(`Request failed with status code ${response.status}: ${errorMessage}`);
        }
    } catch (error) {
        if (alertOnError) {
            const errorMessage = error.response?.data?.detail || error.message;
            console.log(error.response?.data);
            alert(`Error: ${errorMessage}`);
        }
    }
};