import React, { useState, useEffect } from 'react';
import { Input } from './Input';
import ModalOverlay from './ModalOverlay';

function FocusedTextInput({ className = "", placeholder = "Enter text...", onFocusChange, value }) {
    const [isFocused, setIsFocused] = useState(false);
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const handleFocus = () => {
        updateIsFocused(true);
    };

    const handleClose = (e) => {
        const value = e.target.value;
        updateIsFocused(false, value);
    };

    const updateIsFocused = (focusState, value) => {
        const currentFocus = isFocused;
        setIsFocused(focusState);
        if (currentFocus && onFocusChange) {
            onFocusChange(value);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.target.blur();
        }
    };

    return (
        <div>
            {isFocused && <ModalOverlay onClose={handleClose} />}
            <Input
                onFocus={handleFocus}
                onBlur={handleClose}
                onKeyDown={handleKeyPress}
                placeholder={placeholder}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                className={`${className} ${isFocused ? 'focused-input' : ''}`}
            />
        </div>
    );
}

export default FocusedTextInput;