import moment from 'moment';
import { Duration } from 'luxon';

export function formatDate(date) {
    const mDate = moment(date).startOf('day');
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'day').startOf('day');
    const tomorrow = moment().add(1, 'day').startOf('day');
    const nextWeek = moment().add(1, 'week').startOf('day');

    if (mDate.isSame(today, 'day')) {
        return 'Today';
    } else if (mDate.isSame(tomorrow, 'day')) {
        return 'Tomorrow';
    } else if (mDate.isSame(yesterday, 'day')) {
        return 'Yesterday';
    } else if (mDate.isAfter(today) && mDate.isBefore(nextWeek, 'day')) {
        return 'This ' + mDate.format('dddd');
    } else if (mDate.isAfter(nextWeek) && mDate.isBefore(nextWeek.clone().add(1, 'week'))) {
        return 'Next ' + mDate.format('dddd');
    } else {
        return mDate.format('dddd, MMM Do, YYYY');
    }
}


export function formatDuration(isoDuration) {
    try {
      const duration = Duration.fromISO(isoDuration);
      
      if (!duration.isValid) {
        return null;
      }
  
      const days = duration.as('days');
      const hours = duration.as('hours') % 24;
      const minutes = duration.as('minutes') % 60;
  
      const parts = [
        days >= 1 ? `${Math.floor(days)}d` : '',
        hours >= 1 ? `${Math.floor(hours)}h` : '',
        minutes >= 1 ? `${Math.floor(minutes)}m` : '',
      ].filter(Boolean);
  
      return parts.join(' ') || '0m';
    } catch (error) {
      return null;
    }
  }