import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AuthContext from '../auth/AuthContext';
import { makeUnauthenticatedBackendRequest } from '../../api';
import { Link } from 'react-router-dom';


export default function RecipeDetail() {
    const { generated_recipe_uuid } = useParams();
    const [recipe, setRecipe] = useState(null);
    const authContext = useContext(AuthContext);
    const currentUser = authContext.currentUser;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchRecipe = async () => {
            try {
                console.log(generated_recipe_uuid);
                const response = await makeUnauthenticatedBackendRequest(`/recipe/${generated_recipe_uuid}`, 'get');
                console.log(response);
                setRecipe(response.data.recipe.recipe_data);
            } catch (error) {
                console.error(error);
            }
        };
    //
        fetchRecipe();
    }, [generated_recipe_uuid, navigate]);

    return (
        <div className="max-w-[600px] justify-center mx-auto pt-16 px-4">
            {recipe ? (
                <div className="space-y-8">
                    <div className="flex justify-between items-end">
                        <h2 className="h2 flex-grow">{recipe.headline}</h2>
                        {currentUser && <Link to={`/edit_recipe/${generated_recipe_uuid}`} className="underline self-end">Edit</Link>}
                    </div>
                    <div>
                        <h4 className="underline-header">Overview</h4>
                        <p className="pt-2 text-sm">{recipe.description}</p>
                    </div>
                    <div className="space-y-8 md:flex md:space-y-0 md:space-x-4">
                        <div className="md:w-1/2">
                            <h4 className="underline-header">Ingredients</h4>
                            <ul className="list-disc pl-3 pt-2">
                                {recipe.recipeIngredient.map((ingredient, index) => (
                                    <li key={index} className="text-sm">{ingredient}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="md:w-1/2">
                            <h4 className="underline-header">Nutrition Information</h4>
                            <p className="text-sm pt-2">Serves {recipe.recipeYield} people</p>
                            <p className="text-sm">Calories: {recipe.nutrition.calories}</p>
                            <p className="text-sm">Fat: {recipe.nutrition.fatContent}</p>
                            <p className="text-sm">Protein: {recipe.nutrition.proteinContent}</p>
                            <p className="text-sm">Carbohydrates: {recipe.nutrition.carbohydrateContent}</p>
                            {/* Add more nutrition information as needed */}
                        </div>
                    </div>
                    <div>
                        <h4 className="underline-header">Instructions</h4>
                        <ol className="list-decimal pt-2 pl-3">
                            {recipe.recipeInstructions.map((instruction, index) => (
                                <li key={index} className="text-sm">{instruction}</li>
                            ))}
                        </ol>
                    </div>
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
}