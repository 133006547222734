import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper';
import RecipeItem from '../../components/RecipeItem';
import Card from '../../components/Card';
import localIcon from '../../assets/cooking_icon.png';

export default function ScheduledMeal({ meal, mealType }) {
    const swiperRefs = useRef([]);
    const navigate = useNavigate();
    console.log("Meal:", meal);

    const handleCardClick = (mealId) => {
        console.log("Card clicked")
        if (mealId) {
            navigate(`/meal/${mealId}`);
        }
    };

    const renderMeal = (mealName, mealId, recipes) => {
        const hasRecipes = recipes.length > 0;

        return (
            <Card 
                key={mealId} 
                boxShadowColor='green'
                className="cursor-pointer hover:shadow-lg transition-shadow duration-300"
                onClick={() => handleCardClick(mealId)}
            >
                <div>
                    <h5 className="font-bold">
                        Cook {mealName.charAt(0).toUpperCase() + mealName.slice(1)}
                    </h5>
                    {recipes.map(recipeQuantity => (
                        <div key={recipeQuantity.recipe.recipe_id} className="flex items-center py-2">
                            <img src={recipeQuantity.recipe.favicon || localIcon} alt="favicon" className="w-4 h-4 mr-2" />
                            <span className="text-black text-sm">{recipeQuantity.recipe.recipe_name}</span>
                        </div>
                    ))}
                </div>
            </Card>
        );
    };

    if (!meal) {
        return renderMeal(mealType, null, []);
    } else {
        return renderMeal(meal.name || meal.meal_type, meal.id, meal.recipes);
    }
}