import React from 'react';
import { cn } from "src/lib/utils";

export default function Card({ children, className = '', isChecked, boxShadowColor = 'white', alwaysShowShadow = false, onClick = null }) {
    return (
        <div 
            className={cn(
                "flex p-2 border border-black rounded transition-all",
                isChecked ? 'bg-gray-400' : 'bg-gray-200',
                "text-black hover:bg-gray-100",
                alwaysShowShadow ? 'shadow-offset' : 'hover:shadow-offset',
                "hover:-translate-x-boxShadowX hover:-translate-y-boxShadowY",
                className
            )}
            style={{ '--shadow-color': `var(--color-${boxShadowColor})` }}
            onClick={onClick}
        >
            {children}
        </div>
    );
}