import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from '../../components/Dialog';
import { Button } from '../../components/Button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../components/Select';

interface AdjustQuantityModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (multiplier: number) => void;
  initialMultiplier: number;
}

const AdjustQuantityModal: React.FC<AdjustQuantityModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  initialMultiplier
}) => {
  const [multiplier, setMultiplier] = useState(initialMultiplier.toString());

  useEffect(() => {
    setMultiplier(initialMultiplier.toString());
  }, [initialMultiplier]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(parseFloat(multiplier));
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Adjust Recipe Quantity</DialogTitle>
          <DialogDescription>
            Select a multiplier to adjust the recipe quantity.
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="multiplier" className="block text-sm font-medium text-gray-300 mb-2">
              Multiplier
            </label>
            <Select value={multiplier} onValueChange={setMultiplier}>
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select multiplier" />
              </SelectTrigger>
              <SelectContent>
                {[0.5, 1, 1.5, 2, 3, 4].map((value) => (
                  <SelectItem key={value} value={value.toString()}>
                    {value}x
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <DialogFooter>
            <Button type="button" onClick={onClose} variant="secondary">
              Cancel
            </Button>
            <Button type="submit">
              Save
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AdjustQuantityModal;