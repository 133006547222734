export const testRecipe = {
    "id": "zow86YJuRhELaCwrvVhT",
    "updated_at": "2024-05-25T17:24:50.036300+00:00",
    "recipe_id": "nwKv63wGPqIxTSLy5U5h",
    "recipe_type": "external",
    "queued": false,
    "url": "https://cooking.nytimes.com/recipes/1016084-creamy-polenta-with-mushrooms",
    "recipe_name": "Creamy Polenta With Mushrooms",
    "domain": "cooking.nytimes.com",
    "domain_name": "NYT Cooking", // Add this
    "aggreate_rating": { // Add this
        "ratingCount": 20,
        "ratingValue": 4.7
    },
    "timing": {
        "totalTime": "PT150M",
        "cookTime": "PT135M",
    },
    "description": "Who knows who first mixed soy sauce and butter and discovered the pleasures the combination provides. Try the mixture on warm white rice, a steaming pile of greens or an old sneaker – regardless, the taste is a sublime velvet of sweet and salty, along with a kind of pop we call umami, a fifth taste beyond sweet, sour, bitter and salty. Soy butter provides warmth and luxury, elegance without pomp. For this recipe, we’ve adapted a dish that was on the menu at the chef Chris Jaeckle’s All’onda, in Manhattan: a mixture of soy and butter with mushroom stock to pour over polenta and sautéed mushrooms. The result is a dinner of comfort and joy.",
    "processing_complete": true,
    "parsed_ingredients": [
        {
            "aisle": "dairy",
            "parsed_ingredient": "Whole milk",
            "quantity": 1.94,
            "raw_unit_of_measure": "cups",
            "raw_ingredient": "2 cups minus 3 tablespoons whole milk",
            "parsed_unit_of_measure": "Cups"
        },
        {
            "aisle": "other",
            "parsed_ingredient": "Salt",
            "quantity": 1,
            "raw_unit_of_measure": "teaspoon",
            "raw_ingredient": "1 teaspoon salt, or to taste",
            "parsed_unit_of_measure": "Teaspoon"
        },
        {
            "aisle": "other",
            "parsed_ingredient": "Polenta or cornmeal",
            "quantity": 1.75,
            "raw_unit_of_measure": "cups",
            "raw_ingredient": "1 3/4 cups polenta or cornmeal",
            "parsed_unit_of_measure": "Cups"
        },
        {
            "aisle": "dairy",
            "parsed_ingredient": "Unsalted butter",
            "quantity": 4,
            "raw_unit_of_measure": "tablespoons",
            "raw_ingredient": "4 tablespoons unsalted butter, or to taste",
            "parsed_unit_of_measure": "Tablespoons"
        },
        {
            "aisle": "dairy",
            "parsed_ingredient": "Grated Parmesan",
            "quantity": 1,
            "raw_unit_of_measure": "tablespoon",
            "raw_ingredient": "1 tablespoon grated Parmesan, or to taste",
            "parsed_unit_of_measure": "Tablespoon"
        },
        {
            "aisle": "produce",
            "parsed_ingredient": "Dried porcini mushrooms",
            "quantity": 0.5,
            "raw_unit_of_measure": "ounce",
            "raw_ingredient": "1/2 ounce dried porcini mushrooms",
            "parsed_unit_of_measure": "Ounce"
        },
        {
            "aisle": "dairy",
            "parsed_ingredient": "Cold unsalted butter",
            "quantity": 5,
            "raw_unit_of_measure": "tablespoons",
            "raw_ingredient": "5 tablespoons cold unsalted butter, cut into pats, divided",
            "parsed_unit_of_measure": "Tablespoons"
        },
        {
            "aisle": "produce",
            "parsed_ingredient": "Garlic",
            "quantity": 1,
            "raw_unit_of_measure": "clove",
            "raw_ingredient": "1 clove garlic, peeled and minced",
            "parsed_unit_of_measure": "Clove"
        },
        {
            "aisle": "produce",
            "parsed_ingredient": "Fresh mushrooms, wild or cultivated, sliced thin",
            "quantity": 8,
            "raw_unit_of_measure": "ounces",
            "raw_ingredient": "8 ounces fresh mushrooms, wild or cultivated, sliced thin",
            "parsed_unit_of_measure": "Ounces"
        },
        {
            "aisle": "produce",
            "parsed_ingredient": "Fresh thyme leaves",
            "quantity": 1,
            "raw_unit_of_measure": "teaspoon",
            "raw_ingredient": "1 teaspoon fresh thyme leaves",
            "parsed_unit_of_measure": "Teaspoon"
        },
        {
            "aisle": "ethnic",
            "parsed_ingredient": "Soy sauce",
            "quantity": 1,
            "raw_unit_of_measure": "tablespoon",
            "raw_ingredient": "1 tablespoon soy sauce",
            "parsed_unit_of_measure": "Tablespoon"
        },
        {
            "aisle": "dairy",
            "parsed_ingredient": "Heavy cream",
            "quantity": 1,
            "raw_unit_of_measure": "tablespoon",
            "raw_ingredient": "1 tablespoon heavy cream",
            "parsed_unit_of_measure": "Tablespoon"
        },
        {
            "aisle": "ethnic",
            "parsed_ingredient": "Extra-virgin olive oil",
            "quantity": 1,
            "raw_unit_of_measure": "tablespoon",
            "raw_ingredient": "1 tablespoon extra-virgin olive oil",
            "parsed_unit_of_measure": "Tablespoon"
        },
        {
            "aisle": "other",
            "parsed_ingredient": "Freshly ground black pepper",
            "quantity": 0,
            "raw_unit_of_measure": "",
            "raw_ingredient": "Freshly ground black pepper",
            "parsed_unit_of_measure": ""
        }
    ],
    "favicon": "https://static01.nyt.com/applications/cooking/bed7145/assets/apple-touch-icon-152x152-precomposed.png"
    };