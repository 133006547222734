import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import NavbarContext from '../../NavBarContext';
import AuthContext from './AuthContext';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { useGetUserQuery, useCreateUserMutation } from '../../store/apiSlice';

const SignIn = () => {
  const { setShowNavbar } = useContext(NavbarContext);
  const { signInWithGoogle, signInWithEmail } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/schedule';

  const { data: user, error: getUserError, refetch: refetchUser } = useGetUserQuery();
  const [createUser] = useCreateUserMutation();

  useEffect(() => {
    setShowNavbar(false);
    return () => setShowNavbar(true);
  }, [setShowNavbar]);

  const handleSignIn = async (signInMethod) => {
    try {
      await signInMethod();
      await refetchUser();
      
      if (getUserError && (getUserError.status === 401 || getUserError.status === 404)) { // 401 is the status code for unauthorized and 404 is for not found which implies the user is not created yet
        await createUser();
        await refetchUser();
      }
      
      navigate(from, { replace: true });
    } catch (error) {
      console.error('Error during sign in:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleGoogleSignIn = () => handleSignIn(signInWithGoogle);

  const handleEmailSignIn = (e) => {
    e.preventDefault();
    handleSignIn(() => signInWithEmail(email, password));
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="flex flex-col items-center space-y-5 w-full max-w-md px-4">
        <form className="flex flex-col space-y-3 w-full" onSubmit={handleEmailSignIn}>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <Button variant="dark" type="submit">Sign In with Email</Button>
        </form>
        <hr className="border-gray-300 w-full" />
        <Button variant="dark" onClick={handleGoogleSignIn}>Sign In with Google</Button>
      </div>
    </div>
  );
};

export default SignIn;