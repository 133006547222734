// src/components/WebSocketTest.js
import React, { useEffect, useState } from 'react';
import { BASE_WS_URL } from '../constants';

export default function WebSocketTest() {
    const [data, setData] = useState('');

    useEffect(() => {
        const ws = new WebSocket(`${BASE_WS_URL}/websocket_test`);

        ws.onopen = () => {
            console.log('WebSocket is connected');
        };

        ws.onmessage = (event) => {
            console.log('Received data:', event.data);
            setData((prevData) => prevData + event.data);
        };

        ws.onerror = (error) => {
            console.log('WebSocket error:', error);
        };

        ws.onclose = () => {
            console.log('WebSocket is closed');
        };

        return () => {
            ws.close();
        };
    }, []);

    return (
        <div>
            <h1>WebSocket Test</h1>
            {data && <p>Received data: {data}</p>}
        </div>
    );
}